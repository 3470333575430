import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UserServicesModule } from '../services/user-services.module';


import { UserProvider } from './user.provider';


const components = [
    
]

@NgModule({
    declarations: [components],
    exports: [components],
    imports: [ IonicModule, CommonModule,UserServicesModule],
    providers:[UserProvider]
    
})

export class ProvidersModule { }