import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./modules/reference-check/pages/home/home.module').then(m => m.HomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/user/pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'companyProfile',
    loadChildren: () => import('./modules/user/pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'add-reference-check-request',
    loadChildren: () => import('./modules/reference-check/pages/reference-check-request/reference-check-request.module').then(m => m.ReferenceCheckRequestPageModule)
  },
  {
    path: 'edit-reference-check-request',
    loadChildren: () => import('./modules/reference-check/pages/reference-check-request/reference-check-request.module').then(m => m.ReferenceCheckRequestPageModule)
  },
  {
    path: 'view-reference-check-request',
    loadChildren: () => import('./modules/reference-check/pages/reference-check-request/reference-check-request.module').then(m => m.ReferenceCheckRequestPageModule)
  },
  {
    path: 'profile-menu',
    loadChildren: () => import('./modules/reference-check/pages/profile-menu/profile-menu.module').then(m => m.ProfileMenuPageModule)
  },
  {
    path: 'consent-menu',
    loadChildren: () => import('./modules/reference-check/pages/consent-menu/consent-menu.module').then(m => m.ConsentMenuPageModule)
  },
  {
    path: 'profile-listing',
    loadChildren: () => import('./modules/reference-check/pages/profile-listing/profile-listing.module').then(m => m.ProfileListingPageModule)
  },
  {
    path: 'consent-listing',
    loadChildren: () => import('./modules/reference-check/pages/consent-listing/consent-listing.module').then(m => m.ConsentListingPageModule)
  },
  {
    path: 'add-profile',
    loadChildren: () => import('./modules/reference-check/pages/add-profile/add-profile.module').then(m => m.AddProfilePageModule)
  },
  {
    path: 'view-profile',
    loadChildren: () => import('./modules/reference-check/pages/view-profile/view-profile.module').then(m => m.ViewProfilePageModule)
  },
  {
    path: 'add-consent',
    loadChildren: () => import('./modules/reference-check/pages/add-consent/add-consent.module').then(m => m.AddConsentPageModule)
  },
  {
    path: 'view-consent',
    loadChildren: () => import('./modules/reference-check/pages/view-consent/view-consent.module').then(m => m.ViewConsentPageModule)
  },
  {
    path: 'directory-listing',
    loadChildren: () => import('./modules/company-directory/pages/directory-listing/directory-listing.module').then(m => m.DirectoryListingPageModule)
  },
  {
    path: 'add-directory',
    loadChildren: () => import('./modules/company-directory/pages/add-directory/add-directory.module').then(m => m.AddDirectoryPageModule)
  },
  {
    path: 'edit-directory',
    loadChildren: () => import('./modules/company-directory/pages/edit-directory/edit-directory.module').then(m => m.EditDirectoryPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./modules/reference-check/pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
  },
  {
    path: 'edit-consent',
    loadChildren: () => import('./modules/reference-check/pages/edit-consent/edit-consent.module').then(m => m.EditConsentPageModule)
  },
  {
    path: 'listing',
    loadChildren: () => import('./modules/candidates/pages/listing/listing.module').then(m => m.ListingPageModule)
  },
  {
    path: 'candidate',
    loadChildren: () => import('./modules/candidates/pages/candidate/candidate.module').then(m => m.CandidatePageModule)
  },
  {
    path: 'candidate-add',
    loadChildren: () => import('./modules/candidates/pages/candidate-add/candidate-add.module').then(m => m.CandidateAddPageModule)
  },
  // {
  //   path: 'edit-candidate',
  //   loadChildren: () => import('./modules/candidates/pages/candidate-add/candidate-add.module').then(m => m.CandidateAddPageModule)
  // },
  {
    path: 'view-candidate',
    loadChildren: () => import('./modules/candidates/pages/view-candidate/view-candidate.module').then(m => m.ViewCandidatePageModule)
  },
  {
    path: 'request-list',
    loadChildren: () => import('./modules/candidates/pages/request-list/request-list.module').then(m => m.RequestListPageModule)
  },
  {
    path: 'candidate-listing',
    loadChildren: () => import('./modules/candidates/pages/candidate-listing/candidate-listing.module').then(m => m.CandidateListingPageModule)
  },
  {
    path: 'add-reference-history',
    loadChildren: () => import('./modules/candidates/pages/add-reference-history/add-reference-history.module').then( m => m.AddReferenceHistoryPageModule)
  },
  {
    path: 'view-reference-history',
    loadChildren: () => import('./modules/candidates/pages/view-reference-history/view-reference-history.module').then( m => m.ViewReferenceHistoryPageModule)
  },
  {
    path: 'edit-reference-history',
    loadChildren: () => import('./modules/candidates/pages/view-reference-history/view-reference-history.module').then( m => m.ViewReferenceHistoryPageModule)
  },
  {
    path: 'email-phone-verify',
    loadChildren: () => import('./modules/user/pages/email-phone-verify/email-phone-verify.module').then( m => m.EmailPhoneVerifyPageModule)
  },
  {
    path: 'view-candidate',
    loadChildren: () => import('./modules/candidates/pages/view-candidate/view-candidate.module').then( m => m.ViewCandidatePageModule)
  },
  {
    path: 'view-notifications',
    loadChildren: () => import('./modules/user/pages/view-notifications/view-notifications.module').then( m => m.ViewNotificationsPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
