import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
})
export class CheckboxListComponent implements OnInit {

  @Input()
  options: Array<{ name: string, value: string, isSelected: boolean }> = [];
  @Input()
  selectedOptions: Array<string> = [];

  @Output()
  onChange: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {

    this.options= this.options.map(option => {
      option.isSelected = this.selectedOptions.indexOf(option.value) != -1 ? true : false
      return option;
    })

  }

}
