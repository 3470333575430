import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  constructor(public httpService:HttpService) { }

  addReference(body){
    return this.httpService.post("ReferenceCheckRequest/AddReferenceCheckRequest",body);
  }

  getSingleReferenceCheckRequestById(body){
    return this.httpService.post("ReferenceCheckRequest/GetSingleReferenceCheckRequestById",body);
  }

  // addReference(body){
  //   return this.httpService.post("ReferenceCheckRequest/AddReferenceCheckRequest",body);
  // }
}
