import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ReferenceCheckServicesModule } from './services/reference-check-services.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CommonComponentsModule } from 'src/app/components/common/common.components.module';
import { MatSelectModule } from '@angular/material/select';


const components = [
]

@NgModule({
  declarations: [components],
  exports: [components],
  imports: [
    SignaturePadModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ReferenceCheckServicesModule,
    CommonComponentsModule,
    MatSelectModule
  ]
})
export class ReferenceCheck { }
