import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SendReferenceCheckRequestComponent } from 'src/app/modules/reference-check/popups/send-reference-check-request/send-reference-check-request.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { HeaderUserPopoverComponent } from './components/header-user-popover/header-user-popover.component';
import { NotificationPopoverComponent } from './components/notification-popover/notification-popover.component';
import { UpdateProPicComponent } from 'src/app/modules/user/components/update-pro-pic/update-pro-pic.component';
import { MatSelectModule } from '@angular/material/select';
// import { ReportHeaderComponent } from './components/report-header/report-header.component';
// import { SearchAndFilterBarComponent } from './components/search-and-filter-bar/search-and-filter-bar.component';
import { DownloadPopoverComponent } from './components/download-popover/download-popover.component';
import { ReportComponentsModule } from './components/reports/report-components.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SearchFilterModule } from './components/search-and-filters/search-filter.module';


const components = [
  NavbarComponent, NotificationPopoverComponent, UpdateProPicComponent,
  SignaturePadComponent, SendReferenceCheckRequestComponent, HeaderUserPopoverComponent,
  // ReportHeaderComponent,
   DownloadPopoverComponent
];
@NgModule({
  imports: [
    CommonModule, PipesModule, FormsModule,
    IonicModule, SignaturePadModule, MatFormFieldModule, MatInputModule,
    MatSelectModule,
    ReportComponentsModule,
    SearchFilterModule,
    MatButtonToggleModule,
    
  ],
  declarations: components,
  exports: components
})
export class CommonComponentsModule { }
