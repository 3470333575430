import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss'],
})
export class DownloadButtonComponent implements OnInit {


  @Input()
  downloadButtonText: string = "Download";

  @Output()
  download: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  onDownload(event) {
    this.download.emit(event);
  }

  addClick($event){
    
  }

}
