import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  @Input()
  backButtonText: string = "Back";
  @Input()
  backPageNavUrl: string = "";
  @Input()
  defaultHref: string = "";

  @Output()
  onBackClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(private router: Router, private location: Location) { }

  ngOnInit() { }

  backClick(event) {


    if (this.backPageNavUrl) {
      this.router.navigateByUrl(this.backPageNavUrl)
    }
    else {
      //this.router.navigate([".."]);
      this.location.back();
    }
    this.onBackClick.emit(event);
  }

}
