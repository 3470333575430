import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SplitInitCapitalCamelCasePipe } from "./split-init-capital-camel-case.pipe";


@NgModule({
    declarations: [SplitInitCapitalCamelCasePipe],
    imports: [CommonModule],
    exports: [SplitInitCapitalCamelCasePipe]
})

export class PipesModule { }