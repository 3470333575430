import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
// import { DateAdapter } from '@angular/material';


@Component({
  selector: 'search-and-filter-bar',
  templateUrl: './search-and-filter-bar2.component.html',
  styleUrls: ['./search-and-filter-bar.component.scss'],
})
export class SearchAndFilterBarComponent implements OnInit {

  @Output()
  onApplyFilter: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  isSingleQuery;

  searchPlaceholder: string = "Keyword Type";

  @Input()//default dummy filters
  availableSearchAndFilters = {
    filters:
    {
      operation: "and",
      input: [
        // {
        //   name: "anyFilterKey",
        //   displayName: "Any Filter Key",
        //   controlType: "radio",
        //   type: "string",
        //   selectedValue: "one",
        //   width: "200px",
        //   applyOnChange:true,

        //   options: [
        //     { name: "Option1", value: "one" },
        //     { name: "Option2", value: "two" }
        //   ]
        // },
        // {
        //   name: "anyOtherFilterKey",
        //   displayName: "Any Other Filter Key",
        //   controlType: "radio",
        //   type: "boolean",
        //   selectedValue: true,
        //   applyOnChange:true,

        //   options: [
        //     { name: "Yes", value: true },
        //     { name: "No", value: false }
        //   ]
        // },
        // {
        //   name: "oneMoreFilterKey",
        //   displayName: "One More Filter Key",
        //   controlType: "dropdown",
        //   width: "200px",
        //   type: "number",
        //   selectedValue: 3,
        //   applyOnChange:true,

        //   options: [
        //     { name: "1", value: 1 },
        //     { name: "2", value: 2 },
        //     { name: "3", value: 3 },
        //     { name: "4", value: 4 }
        //   ]
        // }
      ]
    },
    query:
    {
      // operation: "or",
       displayName: "Search For",
       inputPlaceHolder: "Search By",
       searchForControlType: "dropdown",
       selectedValue: '',
       searchQuery: "",
       width: "160px",
       applyOnChange:true,
      input: [
        // { name: "Driving License", value: "DrivingLicense" },
        // { name: "First Name", value: "FirstName" },
        // { name: "Middle Name", value: "MiddleName" },
        // { name: "Last Name", value: "LastName" }
      ]
    },
    range: [
      // {
      //   name: "AccidentDate",
      //   displayName: "Accident Date",
      //   controlType: "calender",
      //   type: "date",//date/datetime/time
      //   fixedStartValue: null,
      //   fixedEndValue: new Date(),
      //   applyOnChange:true,
      //   input: {
      //     start: { name: 'AccidentDateStart', value: '' }, end: { name: 'AccidentDateEnd', value: '' }
      //   }
      // },
      // {
      //   name: "CVORPoints",
      //   displayName: "CVOR Points",
      //   controlType: "input",
      //   type: "number",
      //   fixedStartValue: 0,
      //   fixedEndValue: null,
      //   applyOnChange:true,

      //   input: {
      //     start: { name: 'CVORPointsStart', value: '' }, end: { name: 'CVORPointsEnd', value: '' }
      //   }
      // },
      // {
      //   name: "ExpiryDate",
      //   displayName: "Expiry Date",
      //   controlType: "calender",
      //   type: "date",//date/datetime/time
      //   fixedStartValue: new Date(),
      //   fixedEndValue: null,
      //   input: {
      //     start: { name: 'ExpiryStart', value: '' }, end: { name: 'ExpiryEnd', value: '' }
      //   }
      // }
    ]
  }




  // searchFilters = {
  //   filters:
  //   {
  //     operation: "and",
  //     applyOnChange:true,
  //     input: [

  //       {
  //         anyFilterKey: "anyValue"
  //       },
  //       {
  //         anyOtherFilterKey: false
  //       },
  //       {
  //         oneMoreFilterKey: 34
  //       }
  //     ]
  //   },
  //   query:
  //   {
  //     operation: "or",
  //     applyOnChange:true,

  //     input: [
  //       {
  //         anySearchKey: "anyValue"
  //       },
  //       {
  //         anyExtraSearchKey: "anyValue"
  //       }
  //     ]
  //   }
  //   ,
  //   range: [
  //     {
  //       anyRangeKey: { start: "startValue", end: "endValue" }
  //     },
  //     {
  //       anyOtherRangeKey: { start: 10, end: 100 },
  //     }
  //   ]
  // }

  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-US');
  }


  dateChanged(event, filter) {
    filter.input.start.value = event.start;

    filter.input.end.value = event.end;
    filter?.applyOnChange && this.applyFilter()
    
  }
  resetFilters() {

    let filters = {};
    let filter = {
      filters: {
        operation: "and",
        input: this.availableSearchAndFilters.filters.input.map(input => {
          input.selectedValue = 'all';
          return input;
        })
      },
      query: {
        operation: "and",
        input: [(function (availableSearchAndFilters) {
          //let queryObject = {};
          availableSearchAndFilters.query.searchQuery = '';
          //return queryObject
        }(this.availableSearchAndFilters))
        ]
      },
      range: this.availableSearchAndFilters.range.map(range => {
        range.input.start.value = '';

        range.input.end.value = '';
        return range;
      })
    }
    //onsole.log("on Apply", "selected filter", filters, "Available filter", this.availableSearchAndFilters);


    //console.log("Reset this.onApplyFilter.emit");
    this.applySelectedFilter(filters);
  }

  ngOnInit() {

    this.onSearchTypeChanged()

  }

  onSearchTypeChanged() {
    this.searchPlaceholder = this.availableSearchAndFilters?.query?.input.find((x) => { return x.value == this.availableSearchAndFilters?.query?.selectedValue; })?.name || 'Keyword Type';
  }

  getQueryOptions() {

  }

  applyFilter() {

    let filters = {};
    let filter = {
      filters: {
        operation: "and",
        input: this.availableSearchAndFilters.filters.input.map(input => {
          let filter = {};
          if (!input.selectedValue || input.selectedValue.toString().toLowerCase() != 'all')
            filters[input.name] = typeof(input?.selectedValue)=='string'? input?.selectedValue.trim():input?.selectedValue;
          return filter;
        })
      },
      query: {
        operation: "and",
        input: [(function (availableSearchAndFilters) {
          let queryObject = {};
          if (availableSearchAndFilters.query.searchQuery)
            filters[availableSearchAndFilters.query.selectedValue] = (availableSearchAndFilters.query.searchQuery||"").trim();
          return queryObject
        }(this.availableSearchAndFilters))
        ]
      },
      range: this.availableSearchAndFilters.range.map(range => {
        let obj = {};
        // obj[range.name] = { start: range.selectedValue.start, end: range.selectedValue.end }
        if (range.input.start.value) {
          filters[range.input.start.name] = typeof(range.input.start.value)=='string' ?range?.input?.start?.value?.trim() : range.input.start.value;
          //filters[range.input.end.name] = range.input.end.value;
        }
        if (range.input.end.value) {
          //filters[range.input.start.name] = range.input.start.value;
          filters[range.input.end.name] = typeof(range.input.end.value)=='string' ?range?.input?.end?.value?.trim() : range.input.end.value;

        }
        return obj;
      })
    }
    //console.log("Apply this.onApplyFilter.emit");
    this.applySelectedFilter(filters);
  }

  inputChange(e) {
    //console.log(e);
  }


  applySelectedFilter(_filters) {
    of(_filters as any).pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((filters: any) => {
        //console.log("this.onApplyFilter.emit");
        this.onApplyFilter.emit(filters);
        return filters;
      })
    ).subscribe(response => {
      //console.log(response)
    });

  }


}
