import { AfterViewInit, Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EventEmitter } from '@angular/core';
// import moment from 'moment';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, retry, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {

  range: FormGroup = new FormGroup({
    start: new FormControl(null, [Validators.required]),
    end: new FormControl(null, [Validators.required]),
  });

  @Output()
  dateChange: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  displayName: any = "Date Range";// new FormControl(), 

  @Input()
  start: any = "";// new FormControl(),
  @Input()
  end: any = "";// new FormControl(),

  constructor() {



  }

  ngOnInit() {
    this.range.patchValue({ start: this.start, end: this.end })
    this.range.valueChanges.pipe(
      debounceTime(400),
      // distinctUntilChanged(),
      // switchMap(dateT => {
      //   //console.log("dateT", dateT);
      //   if (this.range.valid && dateT.start && dateT.end) {
      //     return dateT;
      //   }
      //   return dateT;
      // }),
      map((dateValue: any) => {
        if (this.range.valid && dateValue?.start && dateValue?.end) {
          var range = {
            start: dateValue.start ? moment(dateValue.start).format("YYYY-MM-DDT00:00:00") : undefined,
            end: dateValue.end ? moment(dateValue.end).format("YYYY-MM-DDT00:00:00") : undefined
          }
          //console.log('this.dateChange.emit', range)

          this.dateChange.emit(range);;
        }
        return dateValue
      }))

      .subscribe((formValue) => {
        // if (this.range.valid && formValue.start && formValue.end) {

        //   of(this.range.value).pipe(
        //     debounceTime(800),
        //     distinctUntilChanged(),
        //     switchMap((dateValue: any) => {
        //       //console.log('this.dateChange.emit', dateValue)

        //       var range = {
        //         start: dateValue.start ? moment(dateValue.start).format("YYYY-MM-DDT00:00:00") : undefined,
        //         end: dateValue.end ? moment(dateValue.end).format("YYYY-MM-DDT00:00:00") : undefined
        //       }
        //       this.dateChange.emit(range);;
        //       return dateValue;
        //     })
        //   ).subscribe(response => () => { });

        // }
      })
  }
  cleared() {
    this.dateChange.emit({});;
  }

}
