import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { ProvidersModule } from './providers/providers.module';
//import { ServicesModule } from './services/services.module';
import { UserModule } from './modules/user/user.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReferenceCheck } from './modules/reference-check/reference-check.module';
// import { CompanyCandidateModule } from './modules/candidates/company-candidate.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DirectivesModule } from './directives/directives.module';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { JwtInterceptor } from './services/interceptors/jwt.interceptor';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,
    UserModule,ReferenceCheck, BrowserAnimationsModule,SignaturePadModule,DirectivesModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },Storage,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
],
  bootstrap: [AppComponent],
  exports:[]
})
export class AppModule {}
