import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { HttpService } from 'src/app/services/http.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from './user.service';


const components = [
    
]

@NgModule({
    declarations: [components],
    exports: [components],
    imports: [ IonicModule, CommonModule],
    providers:[StorageService,HttpService,UserService]
    
})

export class UserServicesModule { }