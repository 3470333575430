import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Login } from '../models/auth';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from '../services/user.service';
import { Storage } from '@ionic/storage';
import { ApiResponse } from 'src/app/models/common.model';
import { ConditionalExpr } from '@angular/compiler';




@Injectable()
export class UserProvider {

  constructor(public userService: UserService, private storageService: StorageService, public router: Router,private storage: Storage) { }

  login(credentials: Login) {
    return this.userService.login(credentials);//.pipe(tap((data:ApiResponse) => {
  }

  loginWithToken(credentials: Login) {
    return this.userService.loginWithToken(credentials);//.pipe(tap((data:ApiResponse) => {
  }

  logout() {
    this.userService.logout();
  }

  changePassword(body) {
    return this.userService.changePassword(body);
  }

  updateProfile(body) {
    return this.userService.updateProfile(body);
  }

  getUserDetails() {
    return this.userService.getUserDetails();
  }

  updateProPic(body) {
    return this.userService.updateProPic(body);
  }
}
