import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ReferenceRequestType } from 'src/app/constants/common';
import { ApiResponse } from 'src/app/models/common.model';
import { AlertMsgProvider } from 'src/app/providers/alert/alert-message.service';
import { ReferenceService } from '../../services/reference.service';

@Component({
  selector: 'app-send-reference-check-request',
  templateUrl: './send-reference-check-request.component.html',
  styleUrls: ['./send-reference-check-request.component.scss'],
})
export class SendReferenceCheckRequestComponent implements OnInit {
  @Input() props: any
  comment = ''
  requestType: ReferenceRequestType;
  referenceCheckOptions = [{ name: "Reference Check", value: 'Reference' }, { name: "Drug", value: 'Drug' }, { name: "Reference And Drug/Alcohol ", value: 'ReferenceAndDrug' }];

  constructor(public loadingController: LoadingController, public referenceService: ReferenceService,
    public alertController: AlertController,private modalController:ModalController,private alertMsgProvider:AlertMsgProvider) { }

  ngOnInit() {
    //console.log(this.props);
  }

  dismiss(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  send() {
    let alert = new AlertComponent(this.alertController);
    if (!this.requestType) {
      alert.errorAlert("", "Validation Error", "Select request type");
    }
    else if (!this.comment) {
      alert.errorAlert("", "Validation Error", "Enter Comments");
    }
    else if (this.comment && this.requestType) {
      let body = {
        referenceCheckCompanyProfileId: this.props.profileId,
        referenceCheckCandidateId: this.props.candidateId,
        referenceCheckReferenceHistoryId: this.props.historyId,
        referenceRequestType: this.requestType,//"Reference",
        comments: this.comment
      }
      this.alertMsgProvider.loading()
      this.referenceService.addReference(body).subscribe((res: ApiResponse) => {
        this.alertMsgProvider.ResponseAlert(res, 'Request', 'Sent', 'Toast', (res) => {
          this.dismiss()
        });
      }, (error) => {
        this.alertMsgProvider.ResponseErrorAlert(error);
      })
    }

  }

  //show loading method
  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
      translucent: true,
      duration: 100
    });
    return await loading.present();
  }

}
