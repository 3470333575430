import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[SINInput]'
})
export class SinInputDirective implements AfterViewInit {
  previousKey: KeyboardEvent;
  generatedElementId: string;

  @Input()
  pattern = ""
  formControl:FormControl;
  constructor(private el: ElementRef, private control : NgControl) {
    this.el.nativeElement.style.backgroundColor = 'yellow';
    var innerContent = document.createElement('div');
    innerContent.innerText = 'Errrrrrrrr'
    // this.el.nativeElement.parentElement.append(innerContent);
    //console.log("SinInputDirective", this.el.nativeElement.parentElement);
  }

  ngAfterViewInit(): void {
    //console.log("pattern", this.pattern);
    //console.log("this.el", this.el);
    //console.log("SinInputDirective form", this.el.nativeElement.form);
    const fun:Function = this.control.control.validator;
    // //console.log("SinInputDirective  form control validator", fun? fun.call(null) :null);
    //console.log("SinInputDirective  form control", this.control);

  //  for (const key of this.el.nativeElement.form) {
  //   //console.log("SinInputDirective form key",key, this.el.nativeElement.form[key].formControlName);

  //  }

    //console.log("SinInputDirective", this.el.nativeElement.parentElement);
    // //console.log("SinInputDirective", this.el.nativeElement.validationMessage="Errrrrrrrrrrr");
    var innerContent = document.createElement('div');
    innerContent.innerText = 'Errrrrrrrr';
    innerContent.style.position = 'absolute';
    innerContent.style.bottom = '0px';
    innerContent.style.color = 'red';
    this.generatedElementId = this.generateId();
    innerContent.id = this.generatedElementId;
    let el = this.el.nativeElement.closest('mat-form-field');
    el.style.position = 'relative'
    el.append(innerContent);
  }

  @HostListener('change')
  ngOnChanges() {
    //console.log('test');
  }

  @HostListener('blur', ['$event'])
  onBlur(event:Event) {
    //console.log('onBlur test');
    this.validateInput(event?.target ["value"]);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!event) {
      return;
    }
    if (this.isControlKey(event) || (this.previousKey?.key == "Control" && event?.code == "KeyV") || /[0-9]/.test(event?.key)) {
      let input: string = event?.target["value"];
      input = input.replace(/[^0-9]/g, "");//replace non numeric values
      this.previousKey = event;

      if (input && input.length >= 9 && !this.isControlKey(event)) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }
    else {
      event.preventDefault();
      event.stopPropagation();
      this.previousKey = event;

      return;
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    let input: string = event.target["value"];
    input = input.replace(/[^0-9]/g, "");//replace non numeric values
    let inputArray = input.split("");
    if (inputArray.length > 3) {
      inputArray.splice(3, 0, "-");
    }
    if (inputArray.length > 7) {
      inputArray.splice(7, 0, "-");
    }
    event.target["value"] = inputArray.join("");
    this.el.nativeElement
  }

  isControlKey(event: KeyboardEvent) {
    return event.key == "ArrowLeft" || event.key == "ArrowRight" || event.key == "Backspace" || event.key == "Tab" || event.key == "Control";
  }

  validateInput(input: string) {
    if (this.pattern) {
      var regEx = new RegExp(this.pattern);
      if(!regEx.test(input)){
        this.showError("Invalid Input");
      }
      else{
        this.hideError();
      }
    }
  }

  showError(message: string) {
    document.getElementById(this.generatedElementId).innerText = message;
    document.getElementById(this.generatedElementId).style.display = "block";
  }

  hideError() {
    document.getElementById(this.generatedElementId).innerText = "";
    document.getElementById(this.generatedElementId).style.display = "none";
  }

  generateId() {
    return "directiveId" + Math.random().toString(16).slice(2);
  }

}
