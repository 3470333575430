import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingController, PopoverController } from '@ionic/angular';
import { AlertMsgProvider } from 'src/app/providers/alert/alert-message.service';
import { FileDownloaderService } from 'src/app/services/file.downloader.service';
// import { FileDownloaderService } from 'src/app/providers/api/file.downloader.service';
import { DownloadPopoverComponent } from '../download-popover/download-popover.component';

@Component({
  selector: 'report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss'],
})
export class ReportHeaderComponent implements OnInit {

  @Input()
  backButtonText: string = "Back";
  @Input()
  recordsType: string = "Records";
  @Input()
  addButtonText: string = "Add Record";
  @Input()
  recordCount: any = "0";
  @Input()
  recordTypes: any = '';
  @Input()
  backPageNavUrl: string = "";
  @Input()
  defaultHref: string = "";

  @Input()
  showDownloadButton: boolean = true;
  
  @Input()
  showAddButton: boolean = true;
  
  @Input()
  downloadApi: string = "";
  // @Input()
  // fullReportApi: string = "";
  // @Input()
  // shortReportApi: string = "";

  // @Input()
  // downloadParams: string = "";
  @Input()
  downloadButtonConfig: {
    fullReportApi: string,
    shortReportApi: string,
    downloadParams: any,
    downloadFileName: string
  }


  // @Input()
  // downloadFileName: string = "";
  @Input()
  showFilterIcon: boolean = false;
  @Input()
  segments: Array<any> = [];

  selectedSegment: string = "";

  @Output()
  onDownloadClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onBackClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onAddClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onFilterClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSegmentChange: EventEmitter<any> = new EventEmitter<any>();


  fileDataType;//:FileDataType = null;


  constructor(private fileDownloadService: FileDownloaderService,
    private popoverController: PopoverController,
    private loadingController: LoadingController,
    private alertMsgProvider: AlertMsgProvider) { }

  companyId: string;

  ngOnInit() {
    this.selectedSegment = this.segments?.length ? this.segments[0].value : "";
  }

  addClick(event) {
    this.onAddClick.emit(event);
  }

  backClick(event) {
    this.onBackClick.emit(event);
  }

  async downloadClick(event) {


    const popover = await this.popoverController.create({
      component: DownloadPopoverComponent,
      event: event,
      componentProps: {},
      backdropDismiss: true,
      showBackdrop: true,
    });

    await popover.present();
    await popover.onDidDismiss().then((res: any) => {
      var data = res?.data;
      //if (data?.data?.companyName) {
      // this.companyInfo.companyName = data?.data?.companyName;
      // }

      if (data) {
        this.fileDataType = `${data?.recordsType}Data${data?.fields}Fields${data?.fileType}`;

        // alert(this.fileDataType);
        switch (this.fileDataType) {
          case `FilteredDataShortFieldsPdf`: {
            break;
          }
          // case `FilteredDataAllFieldsPdf`:{
          //   break;
          // }
          case `FilteredDataShortFieldsExcel`: {
            this.downloadFullDataAsExcel(this.downloadButtonConfig?.shortReportApi || this.downloadApi, this.downloadButtonConfig?.downloadParams);
            break;
          }
          case `FilteredDataAllFieldsExcel`: {
            this.downloadFullDataAsExcel(this.downloadButtonConfig?.fullReportApi || this.downloadApi, this.downloadButtonConfig?.downloadParams);
            break;
          }
          case `AllDataShortFieldsPdf`: {
            break;
          }
          // case `AllDataAllFieldsPdf`:{
          //   break;
          // }
          case `AllDataShortFieldsExcel`: {
            this.downloadFullDataAsExcel(this.downloadButtonConfig?.shortReportApi || this.downloadApi, {});
            break;
          }
          case `AllDataAllFieldsExcel`: {
            this.downloadFullDataAsExcel(this.downloadButtonConfig?.fullReportApi || this.downloadApi, {});
            break;
          }
          default: {
            alert("Invalid Option!");
          }

        }

        this.onDownloadClick.emit({ "fileDataType": this.fileDataType });
      }
    });

  }

  async downloadFullDataAsExcel(_downloadApi, _downloadParams) {
    let params = _downloadParams || { pageNumber: 1, pageSize: -1 };


    this.alertMsgProvider.loading(null).then(() => {
      this
        .fileDownloadService
        .requestFileFromRemote(_downloadApi, 'post', params)
        .toPromise().then(
          (response: any) => {
            this.fileDownloadService.downloadFile(response, this.downloadButtonConfig?.downloadFileName || 'untitled');
          },
          (errors: any) => {
            //console.log(errors);
          }
        );

    }).finally(() => {
      this.loadingController.dismiss();
    })

  }

  onSegmentChanged($event) {
    this.onSegmentChange.emit(this.selectedSegment);
  }


}
