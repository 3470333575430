import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { API_ERRORS, HandledErrorType } from 'src/app/constants/api.errors';
import { splitInitCapitalCamelCase } from 'src/app/helpers/helper';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private storage: Storage,
    private router: Router
  ) { }




  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //return next.handle(req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //console.log('event--->>>', event);

        }
        return event;
      }), catchError((error: HttpErrorResponse) => {

        let handledError: HandledErrorType = null;
        if (error.status === 0) {
          handledError = JSON.parse(JSON.stringify(API_ERRORS[error.status]));
        }

        else if (API_ERRORS[error.status]) {
          handledError = JSON.parse(JSON.stringify(API_ERRORS[error.status]));

          // Object.assign(handledError, API_ERRORS[error.status]);
          if (error.status == 400) {
            handledError.message = handledError.message + "!\n" + error?.error?.title;
            var errors = error?.error?.errors || {};
            handledError.description = "";
            for (const error in errors) {
              if (Object.prototype.hasOwnProperty.call(errors, error)) {
                //const element = errors[error];
                let erMessage = "" + error;
                erMessage = erMessage.replace('.', ' - ');
                erMessage = splitInitCapitalCamelCase(erMessage);
                erMessage = erMessage + "->";
                if ((error || "").indexOf("$.") >= 0) {
                  erMessage = "Invalid input for field " + (error || "").replace("$.", "");
                }
                else
                  errors[error].forEach(element => {
                    erMessage += (erMessage ? "\n" : "") + element;
                  });
                handledError.description = handledError.description + (handledError.description ? "\n" : "") + erMessage;
              }
            }
            // error?.error?.errors.forEach(error => {
            //   handledError.description = handledError.description+ (error.
            //   error
            //  });;
          }

        }

        if (error.status === 401) {
          this.storage.clear().then(() => {
            this.router.navigate(['/login']);
          });
        }
        error = { ...error, ...{ "handledError": handledError || {} } }
        return throwError(error);
      }));


  }

  handleHttpError(error: HttpErrorResponse) {
    //console.log('handleHttpError', error);
    if (error.status === 0) {
      console.log("No internet", error);
      // let alert = new AlertComponent(this.alertController);
      // alert.errorAlert("", "No Internet", "Please Turn Your Internet Connection");

    }

    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        //console.log("Unauthorized err ", error);
        this.storage.clear().then(() => {
          this.router.navigate(['/login']);
        });
      }
      return of(error);

    }
    return throwError(error);
  }




  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   return next.handle(request)
  //     .pipe(
  //       catchError((error: HttpErrorResponse) => {
  //         if (error instanceof HttpErrorResponse) {
  //             if (error.status === 401) {
  //                 this.storage.clear().then(() => {
  //                   this.router.navigate(['/login']);
  //                 });
  //             }
  //         }
  //         return new Observable<HttpEvent<any>>();
  //       })
  //     )
  // }
  // public handleError(error: any, isResponse: boolean = false, showErrorPopup: boolean = true) {

  //   let erMsg = this.errorMessageFor(error);
  //   console.error('Request Error', erMsg);


  //   // if (!isResponse) {
  //   //   error.body = JSON.parse(error._body);
  //   // }
  //   return Observable.throw(error);
  // }

  // private errorMessageFor(error: any) {
  //   const defaultErrMessage = `  network error. <br>  Please retry .`;
  //   let erMsg: string;
  //   switch (error.status) {
  //     case 0: //device Offline
  //       erMsg = `
  //         <p>No internet connection. </p>
  //         <p>Please make sure your device is connected to active internet connection.</p>
  //       `;
  //       break;

  //     case 404:
  //     case 500:
  //       erMsg = error.status + ' - ' + error.statusText;
  //       break;
  //     case 422:
  //       erMsg = error.error.message || error.error.error;
  //       break;
  //     default:
  //       // get message on error
  //       erMsg = error.message;

  //       // Get alternate message if none on the error
  //       if (!erMsg) {
  //         erMsg = error.status
  //           ? error.status + ' - ' + error.statusText
  //           : defaultErrMessage;
  //       }
  //   }
  //   return erMsg;
  // }



}