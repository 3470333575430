import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'download-popover',
  templateUrl: './download-popover.component.html',
  styleUrls: ['./download-popover.component.scss'],
})
export class DownloadPopoverComponent {
  recordsType: string = "";
  fields: string = "";
  slideIndex = 0;
  companyId: string;
  constructor(private popoverController: PopoverController) {
  }

  selectRecordsType(_recordsType) {
    this.recordsType = _recordsType;
  }

  back() {
    if (this.recordsType && this.fields) {
      this.fields = '';
    }
    else if (this.recordsType && !this.fields) {
      this.fields = '';
      this.recordsType = '';
    }
  }

  select(option) {
    this.dismissPopover(option);
  }

  downloadPdf() {
    this.dismissPopover({
      recordsType: this.recordsType,
      fields: this.fields,
      fileType: 'Pdf'
    })
  }

  downloadExcel() {
    this.dismissPopover({
      recordsType: this.recordsType,
      fields: this.fields,
      fileType: 'Excel'
    })
  }

  dismissPopover(data = null) {
    this.popoverController.dismiss(data);
  }

}
