import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileDownloaderService {

  constructor(
    private http: HttpClient
  ) { }

  requestFileFromRemote(requestUrl: string, requestMethod: string, params: any = {}) {
    params = params || {};
    params.pageSize = -1;
    if (requestMethod == 'get') {
      return this.http.get(environment.apiBaseUrl + requestUrl, { responseType: 'blob' as 'json' });
    }
    return this.http.post(environment.apiBaseUrl + requestUrl, params, { responseType: 'blob' as 'json' });
  }

  downloadFile(response: any, filename: string) {
    let dataType = response.type;
    let binaryData = [];
    binaryData.push(response);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    if (filename)
      downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

}
