import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownListComponent implements OnInit {

  @Input()
  optionName: string="";
  @Input()
  options: Array<{ name: string, value: string, isSelected: boolean }> = [];
  @Input()
  selectedOption: string;

  @Output()
  onChange: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {

    // this.options= this.options.map(option => {
    //   option.isSelected = this.selectedOptions.indexOf(option.value) != -1 ? true : false
    //   return option;
    // })

  }

}
