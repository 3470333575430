import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { UserProvider } from 'src/app/modules/user/providers/user.provider';
import { UserService } from 'src/app/modules/user/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { HeaderUserPopoverComponent } from '../header-user-popover/header-user-popover.component';
import { NotificationPopoverComponent } from '../notification-popover/notification-popover.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  userData: any;
  profileData: any;
  pageTitle: any;
  profile: any;
  notifications: any;
  // imgUrl;
  constructor(public userProvider:UserProvider,public router:Router,public storageService:StorageService,
    public popoverCtrl:PopoverController,public userService:UserService) { }

  ngOnInit() {    
    this.userData = JSON.parse(this.storageService.getLocal('user'));

    if(this.storageService.getLocal('companyProfile')) {
      this.profile = JSON.parse(this.storageService.getLocal('companyProfile'));
      this.pageTitle = this.profile.companyName;
    } else {
      this.userService.getCompanyInfo().subscribe((res: any) => {
        this.storageService.localStore('companyProfile', res.output)
        this.profile = JSON.parse(this.storageService.getLocal('companyProfile'));
        this.pageTitle = this.profile.companyName;
      })
      //this.pageTitle = "SAFETY IN MINUTES";
    }

    let body={
      pageNumber: 1,
      pageSize: 6
    }
    this.userService.getNotification(body).subscribe(data=>{
      this.notifications=data['output']
    })
  }

  

  logout(){
    this.userProvider.logout();
  }

  gotoProfile(){
    this.router.navigate(['/profile']);
  }

  async openNotification(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: NotificationPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps:{'notifications':this.notifications}
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
  }

  async userPopover(ev: any){
    const popover = await this.popoverCtrl.create({
      component:HeaderUserPopoverComponent,
      componentProps: {},
      event: ev
    });
    await popover.present();
  }


}
