import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ProvidersModule } from './providers/providers.module';
import { UserServicesModule } from './services/user-services.module';


const components = [

]

@NgModule({
  declarations: [components],
  exports: [components],
  imports: [
    CommonModule,FormsModule,ReactiveFormsModule,IonicModule,ProvidersModule,UserServicesModule
  ]
})
export class UserModule { }
