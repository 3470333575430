import { NgModule } from '@angular/core';
import { SinInputDirective } from './input-directives/sin-input-directive';

const directives = [
  SinInputDirective
];
@NgModule({
  imports: [],
  declarations: directives,
  exports: directives
})
export class DirectivesModule { }
