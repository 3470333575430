import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { ProvidersModule } from 'src/app/providers/providers.module';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
// import { NgbAlertModule, NgbModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
// import { IonicStorageModule } from '@ionic/storage';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { DownloadPopoverComponent } from './download-popover/download-popover.component';


//import { EditAddressComponent } from './address/edit-address/edit-address.component';


let components = [
  ReportHeaderComponent,
  DownloadButtonComponent,
  BackButtonComponent,
  DownloadPopoverComponent
];

@NgModule({
  declarations: [...components],
  exports: components,//export components
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule.forRoot(),
    // ProvidersModule,
    // NgbPaginationModule, NgbAlertModule, NgbTypeaheadModule, NgbModule,
    // IonicStorageModule.forRoot(),
  ]
})
export class ReportComponentsModule { }
