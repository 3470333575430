import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpService {

  apiBaseUrl: string = environment.apiBaseUrl;
  user: any;
  constructor(private http: HttpClient, public storageService: StorageService, public router: Router) {
    if(this.storageService.getLocal('user')) {
      this.user=JSON.parse(this.storageService.getLocal('user'));
    }
  }

  post(method: string, body: any, params: any = {}) {
    const url = this.apiBaseUrl + method;
    return this.http.post(url, body,{});
  }

  postWithParams(method: string, body: any, params: any = {}) {
    this.user=JSON.parse(this.storageService.getLocal('user'));
    const url = this.apiBaseUrl + method;
    return this.http.post(url, body,{
      params:params
    });
  }

  get(method: string, params: any) {
    this.user=JSON.parse(this.storageService.getLocal('user'));
    const url = this.apiBaseUrl + method;
    return this.http.get(url, {
   
    });
  }

  postWithoutToken(method: string, body: any, params: any = {}) {
    const url = this.apiBaseUrl + method;
    return this.http.post(url, body,{
    });
  }

  postfile(method: string, body: any, params: any = {}) {
    this.user=JSON.parse(this.storageService.getLocal('user'));
    const url = this.apiBaseUrl + method;
    return this.http.post(url, body,{
      headers: new HttpHeaders()
      .set('Content-Type', 'multipart/form-data;')
    });
  }


}
