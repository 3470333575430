import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { splitInitCapitalCamelCase } from '../helpers/helper';
 @Pipe({
	name: 'splitInitCapitalCamelCase',
})
export class SplitInitCapitalCamelCasePipe implements PipeTransform {
	transform(str: string, args?: any): any {
		return splitInitCapitalCamelCase(str);
	}
}
