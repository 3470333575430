import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { from, merge, Observable, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { Storage } from "@ionic/storage";
import { UserService } from "src/app/modules/user/services/user.service";
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = null;
    try {
      token = this.userService.getToken();;

    }
    catch (e) {
      console.error(e);
    }
    let userData=JSON.parse(localStorage.getItem('user'))
    return zip(from(token)).pipe(
      switchMap((auth) => {
        if (token) {
          let clonedRequest: HttpRequest<any>;
          clonedRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${auth[0]}`,
              companyId: userData?.companyId? userData.companyId:"",
            },
          });

          if (clonedRequest.body) {
          }
          return next.handle(clonedRequest);
        }
        return next.handle(request);
      })
    );
  }
}
