import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { StorageService } from 'src/app/services/storage.service';
import { Login } from '../models/auth';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { ApiResponse, ResponseStatus } from 'src/app/models/common.model';

export interface UserBasic {
  companyId: string,
  email: string,
  emailConfirmed: boolean,
  firstName: string,
  image: string,
  lastName: string,
  phoneNumber: string,
  phoneNumberConfirmed: boolean,
  role: string,
  userId: string,
}

@Injectable()
export class UserService {
  userData: UserBasic;

  constructor(private httpService: HttpService, public storageService: StorageService, public router: Router,
    private storage: Storage,

    public httpClient: HttpClient) { }

  login(credentials: Login) {
    return this.httpService.postWithoutToken("Auth/Login", credentials).pipe(tap((res: ApiResponse) => {
      if (res.status == ResponseStatus.Ok) {
        this.storage.set('token', res?.output?.token);
        this.storage.set('tokenExpiry', res?.output?.tokenExpiry);
      }
    }));
  }
  loginWithToken(credentials: Login) {
    return this.httpService.postWithoutToken("Auth/loginWithToken", credentials).pipe(tap((res: ApiResponse) => {
      if (res.status == ResponseStatus.Ok) {
        this.storage.set('token', res?.output?.token);
        this.storage.set('tokenExpiry', res?.output?.tokenExpiry);
      }
    }));
  }

  logout() {
    this.storageService.removeLocal('user');
    this.storageService.removeLocal('signature');
    this.storageService.removeLocal('companyProfile');
    this.storage.clear();
    this.router.navigate(['/login']);
    setTimeout(() => {
      location.reload();
    }, 500);
  }

  changePassword(body) {
    return this.httpService.post("Auth/ChangePassword", body);
  }

  updateProfile(body) {
    return this.httpService.post("Auth/EditUser", body);
  }

  getUserDetails() {
    return this.httpService.get("Auth/GetLoggedInUserDetails", {});
  }

  getLoggedInUserProfile() {
    return this.httpService.get("Auth/GetLoggedInUserProfile", {});
  }

  getCompanyInfo() {
    return this.httpService.get("Company/GetCompanyInfo", {}).pipe(tap(async (data: ApiResponse) => {

      if (data?.status  == ResponseStatus.Ok) {
        // //console.log('token', data?.output?.token);
        // this.storage.set('token', data?.output?.token);
        // //console.log('tokenExpiry', data?.output?.tokenExpiry);
        // this.storage.set('tokenExpiry', data?.output?.tokenExpiry);
      }
      return data;
    }));
  }

  updateProPic(body) {
    return this.httpService.postfile("Auth/UpdateUserProfilePicture", body);
  }

  fogotPassword(body) {
    return this.httpService.postWithoutToken("Auth/ForgotPassword", body);
  }

  getNotification(body) {
    return this.httpService.post("Notification/GetMultipleNotificationsRecords", body);
  }

  verifyEmail(params: any) {
    return this.httpService.post('Auth/VerifyEmail', params);
  }

  verifyMobile(params: any) {
    return this.httpService.post('Auth/VerifyMobile', params);
  }


  confirmEmailWithOTP(body, params: any) {
    return this.httpService.postWithParams('Auth/ConfirmEmailWithOTP', body, params);
  }

  confirmMobileWithOTP(body, params: any) {
    return this.httpService.postWithParams('Auth/ConfirmMobileWithOTP', body, params);
  }



  async getToken(keepQueryString = false) {
    const token = await this.storage.get('token');
    const tokenExpiry = await this.storage.get('tokenExpiry');
    if (!tokenExpiry || !token) {
      //this.logout();
      return "";
    }

    if (moment(tokenExpiry).isAfter(new Date())) {
      return token;
    }
    else {
      this.logout();
      return "";
    }
  }


}
