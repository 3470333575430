import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'number-range',
  templateUrl: './number-range.component.html',
  styleUrls: ['./number-range.component.scss'],
})
export class NumberRangeComponent implements OnInit {

  @Input()
  displayName:string= "";
  @Input()
  start:string= "";
  @Input()
  end:string= "";
  constructor() { }

  @Output()
  change1:EventEmitter<any> = new EventEmitter<any>();
  ngOnInit() {}

  onchange(){
    this.change1.emit({start:this.start,end:this.end})
    //console.log("ggggggggggggg");
  }

}
