import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckboxListComponent } from './checkbox-list/checkbox-list.component';
import { IonicModule } from '@ionic/angular';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DropdownListComponent } from './dropdown-list/dropdown-list.component';
import { MatSelectModule } from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';

import { RadioButtonListComponent } from './radio-button-list/radio-button-list.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { NumberRangeComponent } from './number-range/number-range.component';
import { SearchAndFilterBarComponent } from './search-and-filter-bar/search-and-filter-bar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatPaginatorModule} from '@angular/material/paginator';



const components = [
  SearchAndFilterBarComponent,
  CheckboxListComponent,
  DropdownListComponent,
  RadioButtonListComponent,
  DateRangeComponent,
  NumberRangeComponent
];

@NgModule({
  declarations: [...components
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatPaginatorModule
  ],
  exports: [
    // AuthComponentsModule
    ...components
  ]
})
export class SearchFilterModule { }
