import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import {Router } from '@angular/router';
import { UserProvider } from 'src/app/modules/user/providers/user.provider';
//import {UserService } from '../../../providers/user/user.service';
//import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-header-user-popover',
  templateUrl: './header-user-popover.component.html',
  styleUrls: ['./header-user-popover.component.scss'],
})
export class HeaderUserPopoverComponent implements OnInit {
  userData: any;

  constructor(private router: Router,public userProvider:UserProvider,
    private popover: PopoverController,
    ) { }

  ngOnInit() {
    this.userData=JSON.parse( localStorage.getItem('user'));
  }

  logout(){
    this.popover.dismiss();
    this.userProvider.logout();
  }

  gotoUserProfile(){
    this.popover.dismiss();
    this.router.navigateByUrl('/companyProfile');
  }
}
